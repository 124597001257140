import React from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

const SimplePageHeader = ({ title, subtitle }) => (
  <>
    <h1 className="display-4 mb-2">{title}</h1>
    {subtitle && <p className="font-size-lg text-gray-700 mb-md-0">{subtitle}</p>}
    <Row>
      <Col>
        <hr className="mt-6 mt-md-8" style={{ borderColor: "#e2e8f4" }} />
      </Col>
    </Row>
  </>
)

export default SimplePageHeader
